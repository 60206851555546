interface PropsI {
    main: string;
    login: string;
    userdashboard: string;
    navigationmenu: string;
   
  }
  
  // readonly from the interface variables 
  // react-router-dom
  // exported to App.tsx and SignUp.tsx, Login.tsx
  export const paths: Readonly<PropsI> = {
    main: "/",
    navigationmenu: "/navmenu",
    login: "/login",
    userdashboard: "/userdashboard"
   
  };

  export interface PropsIRequest {
    children: any;
  }

  export interface BeforeInstallPromptEvent extends Event {
    readonly platforms: Array<string>;
    readonly userChoice: Promise<{
        outcome: 'accepted' | 'dismissed',
        platform: string
    }>;
    prompt(): Promise<void>;
}