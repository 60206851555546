import React, { Suspense, lazy, useEffect, useState } from "react";
import { Route, Routes } from "react-router";
import Main from "./components/page-one/Main";
import RequestAuth from "./RequestAuth";
import { paths, BeforeInstallPromptEvent } from "./interface";
import Notification from "./components/react-toatify/ReactToastify";
// import { BeforeInstallPromptEvent } from "./pwa";
const NavigationMenu = lazy(() => import("./components/menu/NavigationMenu"));
const Login = lazy(() => import("./components/login/login/Login"));
const UserDashboard = lazy(
  () => import("./components/user-dashboard/UserDashboard"),
);

/////////////////////////
// App,tsx
function App() {
  const [pwaData, setPwaData] = useState<BeforeInstallPromptEvent | null>(null);
  // Disable right-click using useEffect hook
  useEffect(() => {
    const handleContextMenu = (e: any) => {
      e.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event: BeforeInstallPromptEvent) => {
      event.preventDefault();
      console.log(event);
      console.log("before installation prompt fired");
      setPwaData(event); // Using the event directly as BeforeInstallPromptEvent
    };

    window.addEventListener(
      "beforeinstallprompt",
      handleBeforeInstallPrompt as EventListener,
    );

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt as EventListener,
      );
    };
  }, []);

  // console.log(pwaData);

  //  <Notification />  raact-toattify enable the notification work witin the application
  return (
    <>
      <Notification />

      <Suspense
        fallback={
          <div style={{ width: "100%", textAlign: "center", marginTop: "1em" }}>
            Loading.....
          </div>
        }
      >
        <Routes>
          <Route path={paths.main} element={<Main />} />
          <Route path={paths.navigationmenu} element={<NavigationMenu />} />
          <Route
            path={paths.userdashboard}
            element={
              <RequestAuth>
                <UserDashboard />
              </RequestAuth>
            }
          />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
